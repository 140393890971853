import React from "react";
function FeaturesMain() {
  return (
    <div className="px-5 md:px-8">
      <h2 className="dsc-h2 mb-6 text-center leading-none">
        ENTERPRISE EDITION
      </h2>
      <p>
        Droneはあなたがオープンソースプロジェクトを開始する場合でも、自社チーム用の新しいツールを選択する場合でも、その継続的インテグレーションをカバーします。
      </p>
      <p> オープンソース版はApache 2.0ライセンス下でお使いいただけます。</p>

      <p>
        オープンソース版以上のサポートとオートスケーリングなどの機能を求める方は、弊社の営業担当までお問い合わせください。
      </p>
    </div>
  );
}
export default FeaturesMain;
