import React from "react";
import FeaturesBanner from "../components/features/features-banner";
import Layout from "../layout/";
import SectionSpace from "../components/common/section-space";
import FeaturesMain from "../components/features/features-main";
import FeaturesTables from "../components/features/features-table";
import { graphql } from "gatsby";
import SEO from "../components/seo";

const FeaturesPage = ({ data }) => {
  const feature1 = data.strapiFeature.feature1.data.feature1;
  const feature2 = data.strapiFeature.feature2.data.feature2;
  return (
    <Layout>
      <FeaturesBanner />
      <SectionSpace />
      <FeaturesMain />
      <SectionSpace />
      <FeaturesTables tableHtml={feature1} />
      <SectionSpace />
      <FeaturesTables tableHtml={feature2} />
      <SectionSpace />
    </Layout>
  );
};

export const Head = ({ location }) => {
  return (
    <SEO
      title={
        "Drone（Drone.io） エンタープライズ版の機能｜Drone正規販売代理店 DXable"
      }
      description={
        "Drone（Drone.io）では、オープンソース版とエンタープライズ版の2プランをご用意しています。有償のエンタープライズ版では、各種DBやBLOBストレージ、オートスケーリングが利用できるようになり、クラスタ対応やシークレット管理も拡張されます。"
      }
      path={location.pathname}
    />
  );
};

export const query = graphql`
  {
    strapiFeature {
      feature1 {
        data {
          feature1
        }
      }
      feature2 {
        data {
          feature2
        }
      }
    }
  }
`;

export default FeaturesPage;
