import React from "react";

function FeaturesBanner() {
  return (
    <section className="flex flex-col justify-center bg-dsc-theme-dark-bg py-16 px-5 md:px-8">
      <h1 className="dsc-h1 font-normal leading-none text-white ">
        エンタープライズ版の機能
      </h1>
      <p className="mt-6 max-w-3xl leading-normal text-white">
        有償のエンタープライズ版では、各種DBやBLOBストレージ、オートスケーリングが利用できるようになります。クラスタ対応やシークレット管理も拡張されます。
      </p>
    </section>
  );
}
export default FeaturesBanner;
