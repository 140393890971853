import React from "react";
import ReactHtmlParser from "react-html-parser";

type FeatureTableProps = {
  tableHtml: string;
};

function FeaturesTables({ tableHtml }: FeatureTableProps) {
  return (
    <div className="mx-5 md:mx-8 overflow-auto">
      {
        ReactHtmlParser(tableHtml)
        /* <table className="default-table table-fixed min-w-[500px] w-full border-collapse border border-dsc-theme-divier">
        <thead>
          <th></th>
          <th></th>
          <th>Open Source Software</th>
          <th>Enterprise Edition</th>
        </thead>
        <tbody>
          <tr>
            <td>価格</td>
            <td></td>
            <td>Free</td>
            <td>お問い合わせください</td>
          </tr>
          <tr>
            <td>ライセンス</td>
            <td></td>
            <td>Apache 2.0</td>
            <td>Drone Enterprise License</td>
          </tr>
          <tr>
            <td>ソースコード管理</td>
            <td>GitHub</td>
            <td>◯</td>
            <td>◯</td>
          </tr>
          <tr>
            <td></td>
            <td>GitHub Enterprise</td>
            <td>◯</td>
            <td>◯</td>
          </tr>
          <tr>
            <td></td>
            <td>GitLab</td>
            <td>◯</td>
            <td>◯</td>
          </tr>
          <tr>
            <td></td>
            <td>Bitbucket Cloud</td>
            <td>◯</td>
            <td>◯</td>
          </tr>
          <tr>
            <td></td>
            <td>Bitbucket Server</td>
            <td>◯</td>
            <td>◯</td>
          </tr>
          <tr>
            <td></td>
            <td>Gitea</td>
            <td>◯</td>
            <td>◯</td>
          </tr>
          <tr>
            <td></td>
            <td>Gogs</td>
            <td>◯</td>
            <td>◯</td>
          </tr>
          <tr>
            <td>データストレージ</td>
            <td>Sqlite</td>
            <td>◯</td>
            <td>◯</td>
          </tr>
          <tr>
            <td></td>
            <td>MySQL</td>
            <td>—</td>
            <td>◯</td>
          </tr>
          <tr>
            <td></td>
            <td>Postgres</td>
            <td>—</td>
            <td>◯</td>
          </tr>
          <tr>
            <td>BLOBストレージ</td>
            <td>Amazon S3</td>
            <td>—</td>
            <td>◯</td>
          </tr>
          <tr>
            <td></td>
            <td>Minio</td>
            <td>—</td>
            <td>◯</td>
          </tr>
          <tr>
            <td></td>
            <td>Digital Ocean Spaces</td>
            <td>—</td>
            <td>◯</td>
          </tr>
          <tr>
            <td>プラットフォーム</td>
            <td>Linux x64</td>
            <td>◯</td>
            <td>◯</td>
          </tr>
          <tr>
            <td></td>
            <td>Linux armv7</td>
            <td>◯</td>
            <td>◯</td>
          </tr>
          <tr>
            <td></td>
            <td>Linux armv8</td>
            <td>◯</td>
            <td>◯</td>
          </tr>
          <tr>
            <td></td>
            <td>Windows x86</td>
            <td>◯</td>
            <td>◯</td>
          </tr>
          <tr>
            <td>クラスタリング</td>
            <td>単一マシン</td>
            <td>◯</td>
            <td>◯</td>
          </tr>
          <tr>
            <td></td>
            <td>マルチマシン、エージェント</td>
            <td>—</td>
            <td>◯</td>
          </tr>
          <tr>
            <td></td>
            <td>マルチマシン、Kubernetes</td>
            <td>—</td>
            <td>◯</td>
          </tr>
          <tr>
            <td></td>
            <td>マルチマシン、Nomad</td>
            <td>—</td>
            <td>◯</td>
          </tr>
        </tbody>
      </table> */
      }
    </div>
  );
}
export default FeaturesTables;
